// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-category-index-js": () => import("/opt/build/repo/src/templates/SingleCategoryIndex.js" /* webpackChunkName: "component---src-templates-single-category-index-js" */),
  "component---src-templates-single-tag-index-js": () => import("/opt/build/repo/src/templates/SingleTagIndex.js" /* webpackChunkName: "component---src-templates-single-tag-index-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-articles-js": () => import("/opt/build/repo/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-categories-js": () => import("/opt/build/repo/src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-js": () => import("/opt/build/repo/src/pages/layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-tags-js": () => import("/opt/build/repo/src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

